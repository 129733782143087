<template>
    <div ref="el">
        <slot />
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

@Component({})
export default class BlogNext extends Vue {
    @Prop({ required: true }) link: string;
    @Ref('el') el: HTMLElement;
    @Ref('pin') pin: HTMLElement;

    progress = 0;

    created() {
        gsap.registerPlugin(ScrollTrigger);
    }

    mounted() {
        gsap.timeline({
            ease: 'none',
            scrollTrigger: {
                trigger: this.el,
                start: 'bottom bottom',
                end: '+=150%',
                scrub: 0.2,
                pin: '#app'
            },
            onComplete: this.loadNextBlog
        })
            .add(gsap.fromTo(this.el, { clipPath: 'inset(0% 90% 0% 0%)' }, { clipPath: 'inset(0% 0% 0% 0%)' }));
    }

    loadNextBlog() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'blog-endless-mode',
            term: this.link
        });
        window.location.href = this.link;
    }
}
</script>
